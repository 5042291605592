import React, { useState, useRef, useEffect } from 'react';
import { Link} from "react-router-dom";
import { FaUser } from 'react-icons/fa';


const BlurDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        // Ferme le dropdown si le clic est en dehors de l'élément
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

  

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="dropdown">
          <FaUser
                  className="header-image dropdown-button"
                  onClick={toggleDropdown}
                  
          />
            {isOpen && (
             <div className="flex flex-col dropdownProfile">
                <ul className="flex flex-col gap-4 menu"  onClick={(e) => e.stopPropagation()}>
                               <li> <Link to="Login">se connecter</Link> </li>
                </ul>
                </div>
            )}
        </div>
    );
};

export default BlurDropdown;