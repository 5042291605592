import React,{useState,useEffect,useRef} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Users from "./pages/Users";
import Clients from "./pages/GMangemenrt/Clients";
import Categorie from "./pages/GMangemenrt/Categorie";
import Transactions from "./pages/GMangemenrt/Transactions";
import Settings from "./pages/Settings";
import UserProfile from "./pages/UserProfil/UserProfile";
import Timers from "./pages/Timers";
import OTimers from "./pages/OTimer";
import Login from "./pages/Login/Login"
import CryptoJS from "crypto-js";

import "./App.css";

import HTimers from "./pages/HTimers";
import ListeTransactions from "./pages/GMangemenrt/ListeTransactions";
import Dashboard from "./components/Dashboard";
import Rendement from "./pages/GMangemenrt/Rendement";
import LoginID from "./pages/Login/LoginID";

import ClientsFotoana from "./pages/GMangemenrt/clientsFotoana";
import OTransactions from "./pages/GMangemenrt/OTransactions";
import SidebarP from "./components/SidebarP";

function App() {
 const [isConnecter,setIsConnecter]=useState(false);
 const [isOpen, setIsOpen] = useState(true);
 const [isFotoana,setIsFotoana]=useState(true);
 const [isConnect,setIsConnect]=useState(false);
 const secretKey=CryptoJS.SHA1('josuo').toString();

  const toggleSidebar = () => {

    setIsOpen(!isOpen);
  };



    useEffect(() => {
 
     //RECUPERATION DES DONNEES
    const retrievedData=localStorage.getItem('mombamomba');
    /* console.log(decryptedData);*/
     if(retrievedData==null){
       setIsConnect(false);
     }else{
       const decryptedData=decrypt(retrievedData);
       setIsFotoana(decryptedData['type']);
       setIsConnect(true);
    
   
   
     }


    const largeur= window.innerWidth;
    //const longueur=window.innerHeight;

    if(largeur<768){
      setIsOpen(false);
    }

      
  }, []);

    /**************** DECRYPT FUNCTION ***************** */
    const decrypt=(data)=>{
      const bytes=CryptoJS.AES.decrypt(data,secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }


    
  
  return (


    <Router>
      <div className="app">
      {(isFotoana=='J-Fotoana')? <Sidebar isOpen={isOpen} onToggle={toggleSidebar}  />:<SidebarP  isOpen={isOpen} onToggle={toggleSidebar}  />} 
     
        <Header isSidebarOpen={isOpen}  onToggle={toggleSidebar}/>
        <div className="layout">
        
       
          <main   className={isConnect?(isOpen?"content":"content-toggle"):"content-toggle"}>
            <Routes>
              <Route path="/login" element={<Login/>} />
              <Route path="/login/:motCle" element={<LoginID/>} />
              <Route path="/"  element={(isFotoana=='J-Fotoana')?<Timers/>:<ListeTransactions/>} />
              <Route path="/*"  element={(isFotoana=='J-Fotoana')?<Timers/>:<ListeTransactions/>} /> 
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/Clients"  element={<Clients/>} />
              <Route path="/Categorie"  element={<Categorie/>} />
              <Route path="/FClients"  element={<ClientsFotoana/>} />
              <Route path="/transactions"  element={<Transactions/>} />
              <Route path="/transactions/:idClients"  element={<OTransactions/>} />
              <Route path="/Rendement"  element={<Rendement/>} />
              <Route path="/ListeTransactions"  element={<ListeTransactions/>} />
              <Route path="/JTime"  element={<Dashboard/>}/> 
              <Route path="/Timers"  element={<Timers/>} />
              <Route path="/ClientTimers/:idClient"  element={<OTimers/>} />
              <Route path="/Timers/:idHisto/:id_client"  element={<HTimers/>}/>
              <Route path="/users"  element={<Users/>}  />
              <Route path="/usersProfile"  element={<UserProfile/>}  />
              <Route path="/settings" element={<Settings/>}  />
            </Routes>
          </main>
        </div>
        
      </div>
    </Router>
  );
}

export default App;
