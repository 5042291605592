import React, { useState } from "react";
import "./Settings.css";

function Settings() {
  const [formData, setFormData] = useState({
    username: "John Doe",
    email: "johndoe@example.com",
    language: "English",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Settings updated successfully!");
  };

  return (
    <div className="settings">
      <h1>Settings</h1>

      <section className="settings-section">
        <h2>Profile</h2>
        <form onSubmit={handleSubmit} className="settings-form">
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <button type="submit" className="btn-save">Save Changes</button>
        </form>
      </section>

      <section className="settings-section">
        <h2>Application Settings</h2>
        <form onSubmit={handleSubmit} className="settings-form">
          <label>
            Language:
            <select
              name="language"
              value={formData.language}
              onChange={handleChange}
            >
              <option value="English">English</option>
              <option value="French">French</option>
              <option value="Spanish">Spanish</option>
            </select>
          </label>
          <button type="submit" className="btn-save">Save Changes</button>
        </form>
      </section>

      <section className="settings-section">
        <h2>Change Password</h2>
        <form onSubmit={handleSubmit} className="settings-form">
          <label>
            Current Password:
            <input type="password" name="currentPassword" />
          </label>
          <label>
            New Password:
            <input type="password" name="newPassword" />
          </label>
          <label>
            Confirm Password:
            <input type="password" name="confirmPassword" />
          </label>
          <button type="submit" className="btn-save">Update Password</button>
        </form>
      </section>
    </div>
  );
}

export default Settings;
