import React from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";


function Dashboard() {
  const navigate=useNavigate();
  const seconnecter=(mot_cle)=>{
    if(mot_cle=="J-Bot"){
      window.location = "https://ia.pentadev-mada.com/";
    }else{
    navigate("/login/"+mot_cle);
    }
  }
 
  return (
    <div className="dashboard">
    
      <div className="dashboard-widgets">
        <div className="widget">
          <h2>J-Fotoana</h2>
          <p>Application web qui est specialiser en gestion de temps pour "Cyber, salle de jeux, ecole, ..."</p>
          <button className="button btn-success" onClick={()=>seconnecter("J-Fotoana")}>se connecter</button>
        </div>
        <div className="widget">
          <h2>J-Bot</h2>
          <p>Bot special analyse de retoure client qui aide l'utilisateur d'analyser le retoure clients sur son vente ou sur son publication ou sur son article.</p>
          <button className="button btn-success" onClick={()=>seconnecter("J-Bot")}>se connecter</button>
        </div>
        <div className="widget">
          <h2>J-Mandoha</h2>
          <p>Application web qui a pour fonction de payement des salaires des employés</p>
          <button className="button btn-success" onClick={()=>seconnecter("J-Mandoha")}>se connecter</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
