import React, { useState, useEffect } from 'react';
import { addTransaction, getClients } from '../../api';
import { useNavigate,useParams } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import CryptoJS from 'crypto-js';
import './Transactions.css';

const OTransactions = ({ onTransactionSubmit }) => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('withdrawal');
    const [username,setUsername]=useState(null);
    const [isConnect,setIsConnect]=useState(false);
    const secretKey=CryptoJS.SHA1('josuo').toString();
    const {idClients}=useParams();
    const navigate=useNavigate();

    useEffect(() => {
        

                  //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
  /* console.log(decryptedData);*/
   if(retrievedData==null){
     setIsConnect(false);
     console.log(retrievedData);
      navigate('/JTime');
   }else{
   
     const decryptedData=decrypt(retrievedData);
     console.log(decryptedData.username)
     setIsConnect(true);
     setUsername(decryptedData.username);
     fetchClients(decryptedData.username,decryptedData.type);
     setSelectedClient(idClients);
 
   }
    }, [0]);


        /**************** DECRYPT FUNCTION ***************** */
        const decrypt=(data)=>{
          const bytes=CryptoJS.AES.decrypt(data,secretKey);
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      // Charger la liste des clients depuis le backend
       const fetchClients = async (username,type) => {
        try {
          const data = await getClients(username,type);
          console.log(data);
          setClients(data);
        } catch (error) {
          console.error('Erreur lors de la récupération des clients', error);
        
        }
};

    const handleRetourneListe=(e)=>{
        e.preventDefault();
        navigate('/ListeTransactions');
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedClient || !amount) {
            alert('Veuillez remplir tous les champs');
            return;
        }

        const transactionData = {
            client_id: selectedClient,
            amount: parseFloat(amount),
            type,
        };

        try {
           
            await addTransaction(transactionData);
                  //fetchClients();
            setAmount('');
            setSelectedClient('');
            navigate('/ListeTransactions')
            //onTransactionSubmit(); // Rafraîchir la liste des transactions
          
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la transaction:', error);
        }
    };

    return (
        <form className="transaction-form" onSubmit={handleSubmit}>
             <div className='header-content'><h2 >Ajouter une Transaction</h2>   <FaTimes  onClick={(e) => handleRetourneListe(e)}/></div>

            {/* Sélectionner le client existant */}
            <label htmlFor="client">Sélectionner un Client :</label>
            <select 
                id="client" 
                value={selectedClient} 
                onChange={(e) => setSelectedClient(e.target.value)}
            >
                <option value="">-- Sélectionnez un client --</option>
                {clients.map(client => (
                    <option key={client.id} value={client.id}>
                        {client.name}
                    </option>
                ))}
            </select>

            {/* Type de transaction */}
            <label htmlFor="type">Type de Transaction :</label>
            <select 
                id="type" 
                value={type} 
                onChange={(e) => setType(e.target.value)}
            >
                <option value="deposit">Dépôt</option>
                <option value="withdrawal">Retrait</option>
            </select>

            {/* Montant de la transaction */}
            <label htmlFor="amount">Montant :</label>
            <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />

            {/* Bouton de validation */}
            <button type="submit">Valider la Transaction</button>
        </form>
    );
};

export default OTransactions;
