import React, { useState,useEffect } from "react";
import "./UserProfile.css";
import { useNavigate } from "react-router-dom";
import { getCapital,updateCapital,getSommeTrans,getSommePayement } from "../../api";
import CryptoJS from "crypto-js";
import Modal from "../../Modal";

function UserProfile() {


  const [session,setSession]=useState({});
  const navigate=useNavigate();
  const [editCapital,setEditCapital]=useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState({
    name: "...",
    email: "...",
    role: "...",
    profileImage: "https://via.placeholder.com/150", // URL de l'image
  });
  const [capital,setCapital]=useState(0);
  const [idCapital,setIdCapital]=useState(0);
  const [totalTrans,setTotalTrans]=useState(0);
  const [totalRecette,setTotalRecette]=useState(0);
  const [type,setType]=useState('');



const secretKey=CryptoJS.SHA1('josuo').toString();

/**************** DECRYPT FUNCTION ***************** */
const decrypt=(data)=>{
  const bytes=CryptoJS.AES.decrypt(data,secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}


const handleEditCLient=(username)=>{
  setIsModalOpen(true)
}

const handleUpdateCapital=async()=>{
      try {
        await updateCapital(idCapital, { montant:capital, username:user.role, idCapital:idCapital } );
        fetchCapital(user.role); 
        setEditCapital(null)
        setIsModalOpen(false)
      } catch (error) {
        console.error('Erreur lors de la mise à jour du client', error);
       
      }
}


const handleAnnuler=async()=>{
  setIsModalOpen(false)
}


useEffect(()=>{
  //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
 /* console.log(decryptedData);*/
  if(retrievedData==null){
    console.log(retrievedData);
     navigate('/JTime');
  }else{
    const decryptedData=decrypt(retrievedData);
    console.log(decryptedData)
    /*console.log(decryptedData);*/
    setSession(decryptedData)
    setUser({
      name: decryptedData['nom'],
      email: decryptedData['email'],
      role: decryptedData['username'],
      profileImage:'https://gasymeva.pentadev-mada.com/uploads/'+decryptedData['image'], // URL de l'image
    })

     fetchCapital(decryptedData['username']);
     setType(decryptedData.type);
     if(decryptedData.type=="J-Mandoha"){
      fetchSommeTrans(decryptedData['username']);
     }else{

      fetchSommePayement(decryptedData['username']);
     }
    
  }


},[]);



const fetchSommeTrans = async (username) => {

  console.log(username)
  try {
   
    const response = await getSommeTrans(username);
     // Stocke les données de l'utilisateur
   
     setTotalTrans(response[0].total)
  } catch (err) {
     console.log(err);
    
  }
}



const fetchSommePayement = async (username) => {

  console.log(username)
  try {
   
    const response = await getSommePayement(username);
     // Stocke les données de l'utilisateur
     
     setTotalRecette(response[0].total)
  } catch (err) {
     console.log(err);
    
  }
}


    const fetchCapital = async (username) => {
        try {
         
          const response = await getCapital(username);
           // Stocke les données de l'utilisateur
           setCapital(response.data[0].MONTANT);
           setIdCapital(response.data[0].ID_CAPITAL);
       
        } catch (err) {
           console.log(err);
          
        }
      }

 





  return (
    <>
    <div className="user-profile">
      <div className="profile-card">
        <div className="profile-image">
          <img src={user.profileImage} alt="User Profile" />
        </div>
 

        <h2 className="user-name">{user.name}</h2>
        <p className="user-email">{user.email}</p>
        <p className="user-email">Fond monetaire:<b>{capital}AR</b></p>

        {
          (type=='J-Fotoana')?<><p className="user-email">RENDEMENT :<b>{totalRecette}AR</b></p>
        <p className="user-email">Chiffre d'affaire :<b>{parseInt(capital)+parseInt(totalRecette)}AR</b></p></>:<>
        <p className="user-email">Total à payé :<b>{totalTrans}AR</b></p>
        <p className="user-email">Chiffre d'affaire :<b>{capital-totalTrans}AR</b></p></>
        }
       
        <p className="user-role">username:{user.role}</p>
        <button className="edit-button"  onClick={() => handleEditCLient(user.role)}>Edit Capital</button>

      </div>
    </div>


<Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
<h2>Modifier le Capital</h2>
<div className="add-client-form">

<input
  type="number"
  placeholder="Capital"
  value={capital}
  onChange={(e) => setCapital(parseFloat(e.target.value))}
/>
<button onClick={handleUpdateCapital}>Enregistrer</button>
<button className='btn-erreur' onClick={handleAnnuler}>Annuler</button>
</div>
</Modal>
</>
    

    
  );
}

export default UserProfile;
