import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { getClients, addClient, updateClient, deleteClient } from '../../api';
import Modal from '../../Modal';

const ClientsFotoana = () => {
   const [searchTerm, setSearchTerm] = useState("");
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({ name: '', role: '', contact: '',username:'',type:''});
  const [editClient, setEditClient] = useState(null); // Stocke le client à modifier
  const [feedback, setFeedback] = useState('');
   const [isModalOpenAjout, setIsModalOpenAjout] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username,setUsername]=useState(null);
  const [isConnect,setIsConnect]=useState(false);
  const [texte,setTexte]=useState("");
  const [init,setInit]=useState(true);
  const [filter,setFilter]=useState([]);
  const secretKey=CryptoJS.SHA1('josuo').toString();
  const [type,setType]=useState("");
  const navigate=useNavigate();
  const normalizeString = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Récupération initiale des clients
  useEffect(() => {

          //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
  /* console.log(decryptedData);*/
   if(retrievedData==null){
     setIsConnect(false);
     console.log(retrievedData);
      navigate('/JTime');
   }else{


     const decryptedData=decrypt(retrievedData);
     if(decryptedData.type=="J-Fotoana"){
      navigate('/timers');
    }
     console.log(decryptedData.username)
     setIsConnect(true);
     setUsername(decryptedData.username);
     setType(decryptedData.type);
     fetchClients(decryptedData.username,decryptedData.type);
 
   }


  }, []);



    /**************** DECRYPT FUNCTION ***************** */
    const decrypt=(data)=>{
      const bytes=CryptoJS.AES.decrypt(data,secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    

  const fetchClients = async (username,type) => {
    try {
      const data = await getClients(username,type);
      if(init){
      setClients(data);
      }else{
        // Diviser la recherche en mots-clés (par espace ou virgule)
         const valeur =  normalizeString(texte); 
        // Filtrer les éléments
        const filtered = data.filter((item) =>
              normalizeString(item.name).includes(valeur)||normalizeString(String(item.role)).includes(valeur)) // Tous les mots doivent correspondre
        
            ;
      setFilter(filtered);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des clients', error);
      setFeedback('Impossible de charger les clients.');
    }
  };
   

  const filtrage=(valeur)=>{
        
      // Filtrer les éléments
      const filtered = clients.filter((item) =>
        normalizeString(item.name).includes(valeur)||normalizeString(String(item.role)).includes(valeur)) // Tous les mots doivent correspondre
  
      ;
  }

    // Gérer la saisie dans le champ de recherche
    const handleSearch = (event) => {
   
      // Diviser la recherche en mots-clés (par espace ou virgule)
      const value =  normalizeString(texte); 
     
      // Supprime les espaces vides

      // Filtrer les éléments
      const filtered = clients.filter((item) =>
        normalizeString(item.name).includes(value)||normalizeString(String(item.role)).includes(value)) // Tous les mots doivent correspondre
  
      ;
  
      setFilter(filtered);
      setInit(false);
    };

  const ajoutNouveauClient=()=>{
    setIsModalOpenAjout(true);
  }
  const handleEditCLient=async(client)=>{
    setEditClient(client);
    setIsModalOpen(true);
  }
  // Ajouter un nouveau client
  const handleAddClient = async () => {
    try 
    {
      newClient.username=username;
      newClient.type=type;
      await addClient(newClient);
      fetchClients(username,type);
      setIsModalOpenAjout(false);
      setFeedback('Client ajouté avec succès !');
      setNewClient({ name: '', role: '', contact:'' ,username:username,type:type });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du client', error);
      setFeedback('Erreur lors de l\'ajout du client.');
    }
  };

  // Mettre à jour un client existant
  const handleUpdateClient = async () => {
    try {
   
      await updateClient(editClient.id, editClient);
      fetchClients(username,type);
      setFeedback('Client mis à jour avec succès !');
      setEditClient(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du client', error);
      setFeedback('Erreur lors de la mise à jour du client.');
    }
  };

  // Supprimer un client
  const handleDeleteClient = async (id) => {
    try {
      await deleteClient(id);
      setFeedback('Client supprimé avec succès !');
       fetchClients(username,type);
    } catch (error) {
      console.error('Erreur lors de la suppression du client', error);
      setFeedback('Erreur lors de la suppression du client.');
    }
  };

  const handleAjoutTransactions=async(id)=>{
        navigate('/transactions/'+id);
  }

  return (
    <div className="container-timers">
    
  

      {feedback && <p style={{ color: 'green' }}>{feedback}</p>}

      {/* Ajouter un nouveau client */}
      <Modal show={isModalOpenAjout} onClose={() => setIsModalOpenAjout(false)}>
      <h3>Ajouter un Client</h3>
      <div className="add-client-form">
      <input
        type="text"
        placeholder="Nom"
        value={newClient.name}
        onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
      />
      <input
        type="text"
        placeholder="Role"
        value={newClient.role}
        onChange={(e) => setNewClient({ ...newClient, role:e.target.value })}
      />
      <input
        type="number"
        placeholder="Contact"
        value={newClient.contact}
        onChange={(e) => setNewClient({ ...newClient, contact: parseFloat(e.target.value) })}
      />
      <button onClick={handleAddClient}>Ajouter</button>
    </div>
    </Modal>

      {/* Modifier un client */}
      {editClient && (
        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2>Modifier le Client</h2>
          <div className="add-client-form">
          <input
            type="text"
            placeholder="Nom"
            value={editClient.name}
            onChange={(e) => setEditClient({ ...editClient, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="role"
            value={editClient.role}
            onChange={(e) => setEditClient({ ...editClient, role: e.target.value })}
          />
          <input
            type="number"
            placeholder="contact"
            value={editClient.contact}
            onChange={(e) => setEditClient({ ...editClient, contact: parseFloat(e.target.value) })}
          />
          <button onClick={handleUpdateClient}>Enregistrer</button>
          <button className='btn-erreur' onClick={() => setEditClient(null)}>Annuler</button>
          </div>
        </Modal>
      )}
   <div className="table-container">
      {/* Liste des clients */}
      <div className="filter-section">
  <button onClick={ajoutNouveauClient}>Ajout</button>
    {/* Sélecteur de mois */}
  


    {/* Sélecteur d'année */}
   
    <input 
        type="text" 
        value={texte}
        placeholder="recherche..."
        onChange= {(e) => setTexte(e.target.value)}
    />

    {/* Bouton Filtrer */}
    <button onClick={(e)=>handleSearch(e)}>Rechercher</button>
</div>
      <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nom</th>
                        <th>Role</th>
                        <th>Contact</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
        {init?
       ( clients.length!=0?
        clients.map((client) => (

          <tr key={client.id}>
              <td>{client.id}</td>
              <td>{client.name}</td>
              <td>{client.role}</td>
              <td>{client.contact}</td>
              <td><button className='table-btn' onClick={() => handleEditCLient(client)}>Modifier</button>
              <button className='table-btn-suppr' onClick={() => handleDeleteClient(client.id)}>Supprimer</button>
              <button className='table-btn-btn' onClick={() => handleAjoutTransactions(client.id)}>+Payement</button></td>
              
          </tr>
        )):<p>Aucun élément trouvé...</p>):(filter.length!=0? filter.map((client) => (

          <tr key={client.id}>
              <td>{client.id}</td>
              <td>{client.name}</td>
              <td>{client.role}</td>
              <td>{client.contact}</td>
              <td><button className='table-btn' onClick={() => handleEditCLient(client)}>Modifier</button>
              <button className='table-btn-suppr' onClick={() => handleDeleteClient(client.id)}>Supprimer</button>
              <button className='table-btn-btn' onClick={() => handleAjoutTransactions(client.id)}>+Payement</button></td>
              
          </tr>
        )):<p>Aucun Resultat...</p>)}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default ClientsFotoana;
