import React, { useState, useEffect } from "react";
import ClientTimer from "./ClientTimer";
import { getClients, getCategorie, API_URL } from "../api";
import { useNavigate } from "react-router-dom";

import CryptoJS from "crypto-js";
import CreatableSelect from "react-select/creatable";

import "./Timers.css";
import Categorie from "./GMangemenrt/Categorie";

function Timers() {
  /////////////////////////////////////////////////////////////////////
  const [clientsHistorique, setClientsHistorique] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");

  //////////////////////////////////////////////////////////////////////
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // État pour gérer l'ouverture du menu
  const [options, setOptions] = useState([
    { value: "apple__1", label: "🍏 Pomme" },
    { value: "banana__2", label: "🍌 Banane" },
    { value: "cherry__3", label: "🍒 Cerise" },
  ]);
  const [type, setType] = useState("");
  const [username, setUsername] = useState(null);
  const [count, setCount] = useState(0);
  //////////////////////////////////////////////////////////////////////

  const [clients, setClients] = useState([]);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const secretKey = CryptoJS.SHA1("josuo").toString();

  const navigate = useNavigate();
  /////////////////////////////////////////////////////
  /*===============  CONVERSION =====================*/
  const convertDataClient = (data) => {
    //client.id+',,'+client.name
    return data.map((item) => ({
      value: `${item.id},,${item.name}`,
      label: item.name,
    }));
  };
  ///////////////////////////////////////////////////////
  // Charger la liste des clients depuis le backend
  const fetchClients = async (username, type) => {
    try {
      const data = await getClients(username, type);

 
      const option = convertDataClient(data);
      setOptions(option);
      setClientsHistorique(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des clients", error);
    }
  };

  // Charger la liste des categories depuis le backend
  const fetchCategories = async (username, type) => {
    try {
      const data = await getCategorie(username, type);

      setCategorie(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des clients", error);
    }
  };

  const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  useEffect(() => {
    //RECUPERATION DES DONNEES
    const retrievedData = localStorage.getItem("mombamomba");

    if (retrievedData == null) {
      navigate("/JTime");
    } else {
      const decryptedData = decrypt(retrievedData);
      if (decryptedData["type"] == "J-Fotoana") {
        fetchClients(decryptedData.username, decryptedData.type);
        fetchCategories(decryptedData.username, decryptedData.type);
        setType(decryptedData.type);
        setUsername(decryptedData.username);
      } else if (decryptedData["type"] == "J-Mandoha") {
        navigate("/ListeTransactions");
      }
    }

    // fetchMessages();
  }, [0]);

  /*OLDER*********************************************************
    const addClient = () => {
       // const arrayData = selectedClient.split(",,");
       const arrayData = selectedOption.value.split(",,");
       
        if (selectedClient !== "" && selectedCategorie!=="") {
            setClients([
                ...clients,
                {
                    id:arrayData[0],
                    idCategorie:selectedCategorie,
                    name: arrayData[1],
                    minutes: parseInt(minutes),
                    seconds: parseInt(seconds),
                    minuteRestant:parseInt(minutes),
                    secondeRestant: parseInt(seconds),
                    status:"Pause",
                    paye:false,
                    
                }
            ]);
            setSelectedClient('');
            setMinutes(0);
            setSeconds(0);
        }
            
       
    };
    **************************************************/
  // Fonction pour ajouter une nouvelle option lors de l'appui sur "Entrée"
  // Fonction pour ajouter une nouvelle option si elle n'existe pas

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const addClient = () => {
    // const arrayData = selectedClient.split(",,");
    const arrayData = selectedOption.value.split(",,");

    if (selectedOption.value !== "" && selectedCategorie !== "") {
      setCount(count + 1);
      setClients([
        ...clients,
        {
          increment: count,
          id: arrayData[0],
          idCategorie: selectedCategorie,
          name: arrayData[1],
          minutes: parseInt(minutes),
          seconds: parseInt(seconds),
          minuteRestant: parseInt(minutes),
          secondeRestant: parseInt(seconds),
          status: "Pause",
          paye: false,
        },
      ]);
      setSelectedClient("");
      setMinutes(0);
      setSeconds(0);
    }
  };

  const handleCreate = (inputValue) => {
    const newOption = {
      name: inputValue.toLowerCase(),
      label: inputValue,
      username: username,
      type: type,
    };

    fetch(API_URL + "/clients", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newOption),
    })
      .then((response) => response.json())
      .then((data) => {
        const newOption = {
          value: `${data.id},,${data.name.toLowerCase()}`,
          label: data.name,
        };
        setOptions([...options, newOption]); // Mettre à jour la liste
        setSelectedOption(newOption); // Sélectionner la nouvelle option
      })
      .catch((error) => console.error("Erreur ajout option:", error));
  };

  //////////////////////////////////////////////////////////////

  /* Fonction pour ajouter une nouvelle option si elle n'existe pas
    const handleChange = async(newValue) => {
   
        if (!newValue) return; // Éviter les valeurs vides

        // Vérifier si l'option existe déjà
        const exists = options.some(opt => opt.label.toLowerCase() === newValue.label.toLowerCase());

        if (!exists) {
            
          
            const newClient={ name: newValue.label, contact: '',username:username,type:type}
            console.log(newClient);
       
            const reponse= addClient(newClient);

        
            const newId = options.length + 1; // Générer un nouvel ID
            const newOption = { value: `${newValue.label.toLowerCase()}__${newId}`, label: newValue.label };

            setOptions([...options, newOption]); // Ajouter à la liste
            setSelectedOption(newOption); // Sélectionner la nouvelle option
        } else {
            setSelectedOption(newValue); // Si elle existe déjà, juste la sélectionner
        }
        
    };*/

  // Fonction pour ajouter une nouvelle option lors de l'appui sur "Entrée"
  const handleKeyDown = (event, inputValue) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault(); // Empêcher la soumission par défaut
      const newOption = {
        name: inputValue.toLowerCase(),
        label: inputValue,
        username: username,
        type: type,
      };

      fetch(API_URL + "/clients", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newOption),
      })
        .then((response) => response.json())
        .then((data) => {
          const newOption = {
            value: `${data.id},,${data.name.toLowerCase()}`,
            label: data.name,
          };
          setOptions([...options, newOption]); // Mettre à jour la liste
          setSelectedOption(newOption); // Sélectionner la nouvelle option
        })
        .catch((error) => console.error("Erreur ajout option:", error));
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////

  const removeClient = (increment) => {
    setClients(clients.filter((client) => client.increment !== increment));
    const newMinuters = {
      nom: increment,
      minutes: minutes,
      seconds: seconds,
      etat: "supression",
    };
    console.log(newMinuters);
  };

  return (
    <div className="container-timers">
      <div className="add-client-form">
        <CreatableSelect
          options={options}
          value={selectedOption}
          onChange={setSelectedOption}
          onCreateOption={handleCreate} // Gérer l'ajout
          placeholder="Clients..."
          isClearable
          isSearchable
          noOptionsMessage={() => "Tapez pour ajouter une nouvelle option"}
          onKeyDown={(event) => handleKeyDown(event, event.target.value)}
          menuIsOpen={menuOpen} // Contrôle manuel du menu
          onMenuOpen={() => setMenuOpen(true)} // Ouvrir le menu
          onMenuClose={() => setMenuOpen(false)} // Fermer le menu
        />

        <select
          id="categorie"
          value={selectedCategorie}
          onChange={(e) => setSelectedCategorie(e.target.value)}
          isSearchable
        >
          <option value="">Categorie </option>
          {categorie.map((categorie) => (
            <option key={categorie.ID} value={categorie.ID}>
              {categorie.LIBELLE}
            </option>
          ))}
        </select>

        <input
          type="number"
          placeholder="Minutes"
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          min="0"
        />
        <span className="text-temps"> min</span>

        <input
          type="number"
          placeholder="Secondes"
          value={seconds}
          onChange={(e) => setSeconds(e.target.value)}
          min="0"
          max="59"
        />
        <span className="text-temps">sec</span>
        <button onClick={addClient}>Ajouter Client</button>
      </div>
      <div className="clients-container">
        {clients.map((client) => (
          <ClientTimer
            increment={client.increment}
            idClient={client.id}
            idCategorie={client.idCategorie}
            key={client.name}
            name={client.name}
            minuteRestant={client.minuteRestant}
            secondeRestant={client.secondeRestant}
            initialMinutes={client.minutes}
            paye={client.paye}
            initialSeconds={client.seconds}
            status={client.status}
            onRemove={removeClient}
          />
        ))}
      </div>
    </div>
  );
}

export default Timers;
