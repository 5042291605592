import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {getTransactions,deleteTransaction } from '../../api';
import Modal from '../../Modal';
import './ListeTransactions.css';
import { FaEye,FaTrash,FaTimes } from 'react-icons/fa';

const ListeTransactions = () => {
  const [Transactions, setTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({ name: '', role: '', contact: '',username:''});
  const [editTransaction, setEditTransaction] = useState(null); // Stocke le Transaction à modifier
  const [feedback, setFeedback] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username,setUsername]=useState(null);
  const [isConnect,setIsConnect]=useState(false);
  const [totalTransaction,setTotalTransaction]=useState(0);
  const secretKey=CryptoJS.SHA1('josuo').toString();
  const navigate=useNavigate();
  const [month, setMonth] = useState(new Date().getMonth()+1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [filteredTransactions, setFilteredTransactions] = useState(null);





  // Récupération initiale des Transactions
  useEffect(() => {

          //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
  /* console.log(decryptedData);*/
   if(retrievedData==null){
     setIsConnect(false);
     console.log(retrievedData);
      navigate('/JTime');
   }else{


     const decryptedData=decrypt(retrievedData);
     if(decryptedData.type=="J-Fotoana"){
      navigate('/timers');
    }
     console.log(decryptedData.username)
     setIsConnect(true);
     setUsername(decryptedData.username);
     fetchTransactions(decryptedData.username);
      /*************************************/
      //setYear(new Date().getFullYear());
      //setMonth(new Date().getMonth()+1);

      /*************************************/
 
   }


  }, []);



    /**************** DECRYPT FUNCTION ***************** */
    const decrypt=(data)=>{
      const bytes=CryptoJS.AES.decrypt(data,secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    

/***************** AJOUT NOUVEAU PAYEMENT *********************/
    const AjoutNouveauPayement = () => {
    navigate('/Transactions');

  };

/********************FIlTRAGE APRES CLIQUES BOUTONS********************* */
    const filterTransactions = () => {
        fetchTransactions(username);
    
    };
  
  const fetchTransactions = async (username) => {
    try {
      const data = await getTransactions(username);
 
      const filtered = data.filter(transaction => {
        const transactionDate = new Date(transaction.date_heure);
        return (
            (!month || transactionDate.getMonth() + 1 === parseInt(month)) &&
            (!year || transactionDate.getFullYear() === parseInt(year))
        );
    });


    const totalAmount = filtered.reduce((sum, item) => sum + parseInt(item.amount), 0);
    setTotalTransaction(totalAmount);
    setTransactions(filtered);
    } catch (error) {
      console.error('Erreur lors de la récupération des Transactions', error);
      setFeedback('Impossible de charger les Transactions.');
    }
  };

  const handleEditTransaction=async(Transaction)=>{
    setEditTransaction(Transaction);
    setIsModalOpen(true);
  }
  // Ajouter un nouveau Transaction
  const handleAddTransaction = async () => {
    try 
    {
      newTransaction.username=username;
      //await addTransaction(newTransaction);
      fetchTransactions(username);
      setFeedback('Transaction ajouté avec succès !');
      setNewTransaction({ name: '', role: 0, contact: 0,username:username });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du Transaction', error);
      setFeedback('Erreur lors de l\'ajout du Transaction.');
    }
  };

  // Mettre à jour un Transaction existant
  const handleUpdateTransaction = async () => {
    try {
   
      //await updateTransaction(editTransaction.id, editTransaction);
      fetchTransactions(username);
      setFeedback('Transaction mis à jour avec succès !');
      setEditTransaction(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du Transaction', error);
      setFeedback('Erreur lors de la mise à jour du Transaction.');
    }
  };

  // Supprimer un Transaction
  const handleDeleteTransaction = async (id) => {
    try {

      
      await deleteTransaction(id);
      fetchTransactions(username);
      setFeedback('Transaction supprimé avec succès !');
    } catch (error) {
      console.error('Erreur lors de la suppression du Transaction', error);
      setFeedback('Erreur lors de la suppression du Transaction.');
    }
  };

/******************************************************/
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
};
/*********************************************************** */
  return (
    <div className="container-timers">
    
  

      {feedback && <p style={{ color: 'green' }}>{feedback}</p>}

      <h2>Listes des Transactions</h2>

<div className="filter-section">
  <button onClick={AjoutNouveauPayement}>Ajout</button>
    {/* Sélecteur de mois */}
  
    <select value={month} onChange={(e) => setMonth(e.target.value)}>
        <option value="">Tous</option>
        {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
                {new Date(2025, i).toLocaleString('fr-FR', { month: 'long' })}
            </option>
        ))}
    </select>

    {/* Sélecteur d'année */}
   
    <input 
        type="number" 
        value={year} 
        onChange={(e) => setYear(e.target.value)} 
        placeholder="2025"
    />

    {/* Bouton Filtrer */}
    <button onClick={filterTransactions}>Filtrer</button>
</div>
      {/* Modifier un Transaction */}
      {editTransaction && (
        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
           <div className='header-content'><h2 >Détail de Transaction</h2>   <FaTimes  onClick={() => setEditTransaction(null)}/></div>
          <div className="add-Transaction-form align-justify">
            <div className='row'><div className='col-2'>Nom</div><div className='col-10'>{editTransaction.name}</div></div>
            <div className='row'><div className='col-2'>Role</div><div className='col-10'>{editTransaction.role}</div></div>
            <div className='row'><div className='col-2'>Contact</div><div className='col-10'>{editTransaction.contact}</div></div>
            <div className='row'><div className='col-2'>Montant</div><div className='col-10'>{editTransaction.amount} AR</div></div>
            <div className='row'><div className='col-2'>Date</div><div className='col-10'>{formatDate(editTransaction.date_heure)}</div></div>
          
          </div>
       
        </Modal>
      )}
    {Transactions.length!=0? 
    <div>
    <div>Total de payement dans ce mois:<b>{totalTransaction} AR</b></div>
   <div className="table-container">
      {/* Liste des Transactions */}
      
      <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Nom</th>
                        <th>Role</th>
                        <th>Montant</th>
                        <th>Contact</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
        {Transactions.map((Transaction) => (

          <tr key={Transaction.id}>
              <td>{Transaction.id}</td>
              <td>{formatDate(Transaction.date_heure)}</td>
              <td>{Transaction.name}</td>
              <td>{Transaction.role}</td>
              <td>{Transaction.amount}</td>
              <td>{Transaction.contact}</td>
              
              <td><button className='table-btn' onClick={() => handleEditTransaction(Transaction)}><FaEye/></button>
              <button className='table-btn-suppr' onClick={() => handleDeleteTransaction(Transaction.id)}><FaTrash/></button></td>
          </tr>
        ))}
        </tbody>
      </table>
      </div>
      </div>
      :  <p>Aucun élément trouvé.</p>}

    </div>
  );
};

export default ListeTransactions;
