import React, { useState,useEffect } from "react";
import { Link,replace,useNavigate,useParams } from "react-router-dom";
import {deleteTimer, fetchTimers} from "../api";
import CryptoJS from "crypto-js";
import { FaSearch, FaArrowLeft, FaArrowRight, FaTimes} from "react-icons/fa";
import { FiRefreshCcw } from 'react-icons/fi';
import "./Sidebar.css";

const Sidebar=({isOpen,onToggle})=> {
 
  const [searchTerm, setSearchTerm] = useState("");
  const [historique,setHistorique]=useState([]);
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const [filter,setFilter]=useState([]);

  const [init,setInit]=useState(true);
  const [misokatra,setMisokatra]=useState(isOpen);
  const [idHisto,setIdHisto]=useState(0);
  const [isConnect,setIsConnect]=useState(false);
    const secretKey=CryptoJS.SHA1('josuo').toString();
  const navigate=useNavigate();

  const normalizeString = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  
  /**************** DECRYPT FUNCTION ***************** */
  const decrypt=(data)=>{
    const bytes=CryptoJS.AES.decrypt(data,secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  

  useEffect(() => {
   
    const t=fetchTimers().then(setHistorique).catch((err) => console.error(err));
    console.log(t)
    setLoading(true);
   //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
  /* console.log(decryptedData);*/
   if(retrievedData==null){
     setIsConnect(false);
      navigate('/JTime', { replace: true });
   }else{
     const decryptedData=decrypt(retrievedData);
     setIsConnect(true);
 
   }

    
}, []);


const formatDate = (isoDateString) => {
  const date = new Date(isoDateString); // Crear un objeto Date
  const day = String(date.getDate()).padStart(2, '0'); // Día con 2 dígitos
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos
  const year = date.getFullYear(); // Año completo
  return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
};

  // Gérer la saisie dans le champ de recherche
  const handleSearch = (event) => {
   
    // Diviser la recherche en mots-clés (par espace ou virgule)
    const value =  normalizeString(event.target.value); 
    setSearchTerm(event.target.value);
    
    // Supprime les espaces vides

    // Filtrer les éléments
    const filtered = historique.filter((item) =>
      normalizeString(item.NOM).includes(value)||normalizeString(String(item.STATUS)).includes(value)) // Tous les mots doivent correspondre

    ;

    setFilter(filtered);
    setInit(false);
  };


const handleDelete = async(index) => {


   const response=await deleteTimer(index);

   if(response.data['success']){
    if(init){
    setHistorique(historique.filter((item) => item.ID !==index)); // Mettre à jour la liste
    }else{
          // Filtrer les éléments
    const filtered = historique.filter((item) =>
      (normalizeString(item.NOM).includes(searchTerm)||normalizeString(String(item.STATUS)).includes(searchTerm))&&item.ID !==index) // Tous les mots doivent correspondre;
    setFilter(filtered)
    }
   }
 /* try{
      const response=await axios.delete('http://localhost:5000//api/delete_prepa/'+index);
      if(response.data['success']){
            const newData = [...data];
            newData.splice(index2, 1);
            setData(newData);
      }
   }catch(error){
    console.log(error)
   }
*/


};



const handleLink = async(path,id) => {
  if(idHisto!=id){
  navigate(path);

  const largeur= window.innerWidth;
  //const longueur=window.innerHeight;

  if(largeur<768){
    navigate(0)
  }

  }else{

    navigate(path);
    navigate(0)
  }

  setIdHisto(id);
  
};

const onActualise=async()=>{
  fetchTimers().then(setHistorique).catch((err) => console.error(err));
  if(!init){

         // Filtrer les éléments
    const filtered = historique.filter((item) =>
          (normalizeString(item.NOM).includes(searchTerm)||normalizeString(String(item.STATUS)).includes(searchTerm))) // Tous les mots doivent correspondre;
        setFilter(filtered)
 

}
}




  return (

    (!isConnect)?"":(
    <aside className={`sidebar ${isOpen ? "open" : "closed"}`}>

      <nav>
    
      <div className="sidebar-header">
      
        {isOpen && (
          <div className="search-box">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Historique..."
              value={searchTerm}
             
              className="search-input"
  
              onChange={(e)=>handleSearch(e)}
            />
          </div>
        )}
 <button className="toggle-btn" onClick={onActualise} >
                 <FiRefreshCcw/>
           </button>
<button className="toggle-btn"  onMouseDown={onActualise} onClick={onToggle}>

          {isOpen ? <FaArrowLeft /> : <FaArrowRight />}
        </button>
      </div>
      <div className="sidebarCorps">
      {!loading ? (
        <p>Chargement...</p>
      ):(historique.length > 0 || filter.length>0) ?( 
        <ul className="sidebar-menu">
        {init?(historique.map(item => (
          (item.COMMENTAIRE)?(<li key={item.ID+''+item.DATE_CREATE} ><div className="sous-menu" style={{fontWeight:600}}><a onClick={()=>handleLink('Timers/'+item.ID+'/'+item.ID_CLIENT+',,'+item.NOM,item.ID_CLIENT)}><div>..{formatDate(item.DATE_CREATE)},{item.NOM}:{item.COMMENTAIRE}</div>   </a><div></div></div></li>):(
          <li key={item.ID+''+item.DATE_CREATE} ><div className="sous-menu"><a onClick={()=>handleLink('Timers/'+item.ID+'/'+item.ID_CLIENT+',,'+item.NOM,item.ID_CLIENT)}><div>..{formatDate(item.DATE_CREATE)},{item.NOM}:{item.STATUS}</div>   </a><div>{item.PAYE==1? <span className="label classic"> Payé</span>:  <span className="label classic-np"> non Payé</span>}</div><FaTimes className="icon-x" onClick={()=>handleDelete(item.ID)}/></div></li>)
        ))):(filter.map(item => (
          (item.COMMENTAIRE)?(<li key={item.ID+''+item.DATE_CREATE} ><div className="sous-menu" style={{fontWeight:600}}><a onClick={()=>handleLink('Timers/'+item.ID+'/'+item.ID_CLIENT+',,'+item.NOM,item.ID_CLIENT)}><div>..{formatDate(item.DATE_CREATE)},{item.NOM}:{item.COMMENTAIRE}</div>   </a><div></div></div></li>):(
            <li key={item.ID+''+item.DATE_CREATE} ><div className="sous-menu"><a onClick={()=>handleLink('Timers/'+item.ID+'/'+item.ID_CLIENT+',,'+item.NOM,item.ID_CLIENT)}><div>..{formatDate(item.DATE_CREATE)},{item.NOM}:{item.STATUS}</div>   </a><div>{item.PAYE==1? <span className="label classic"> Payé</span>:  <span className="label classic-np"> non Payé</span>}</div><FaTimes className="icon-x" onClick={()=>handleDelete(item.ID)}/></div></li>)    )))}

     

        </ul>): (
        <p>chargement...</p>
      )
}
        </div>
      </nav>
    </aside>
    )
  );
}

export default Sidebar;
