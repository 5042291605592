import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import "./AdvancedSelect.css";
const AdvancedSelect = () => {
  const options = [
    { id: 0, value: "Timers" },
    { id: 1, value: "Fotoana" },
    { id: 6, value: "Clients" },
    { id: 2, value: "Mampiditra Fotoana" },
    { id: 3, value: "Rendement" },
    { id: 4, value: "Historique" },
  ];

  const optionsMandoha = [
    { id: 0, value: "Salaire" },
    { id: 1, value: "Clients" },
    { id: 2, value: "Payement" }

  ];
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("J-Fotoana");
  const [selectedOption, setSelectedOption] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const secretKey = CryptoJS.SHA1("josuo").toString();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  // Gestion du clic en dehors du panneau
  useEffect(() => {
    const retrievedData = localStorage.getItem("mombamomba");
    /* console.log(decryptedData);*/
    if (retrievedData != null) {
      const decryptedData = decrypt(retrievedData);
      if(decryptedData["type"]=="J-Fotoana"){
        setFilteredOptions(options);
      }else{
        setFilteredOptions(optionsMandoha);
        
      }
      setType(decryptedData["type"]);
    }

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const normalizeString = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  //TAPE TOUCHE ENTRER APRES LA SAISIE DES VALEURS A ENTRER
  const handleKeyDownInput = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      alert(inputValue);
      setInputValue(""); // Réinitialise l'entrée
    }
  };
  // Filtrage des options
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(true);

    const valeur = normalizeString(value);

    const filtered = options.filter((item) =>
      normalizeString(item.value).includes(value)
    ); // Tous les mots doivent correspondre

    if (filtered.length == 0) {
      setFilteredOptions([{ id: 0, value: "Aucun resultat" }]);
    } else {
      setFilteredOptions(filtered);
    }
  };

  // Sélection d'une option
  const handleSelectOption = (option) => {
    setSelectedOption(option);

    /*{ id: 2, value: "Mampiditra Fotoana" },
    { id: 3, value: "Rendement" },
    { id: 4, value: "Historique" },
    { id: 5, value: "Inscriptions" },
  ];*/
   if(type=="J-Fotoana"){
    switch (option) {
      case "Timers":
        navigate("/Timers");
        break;

      case "Rendement":
        navigate("/Rendement");
        break;
      case "Fotoana":
        navigate("/Timers");
        break;
      case "Mampiditra Fotoana":
        navigate("/Timers");

        break;
      case "Historique":
        navigate("/Rendement");

        break;

      default:
        console.log("tsy agnatiny...");
      // Code exécuté si aucune valeur ne correspond
    }
  
  }else{


    /*  { id: 0, value: "Salaire" },
    { id: 1, value: "Clients" },
    { id: 2, value: "Payement" }*/
    switch (option) {
      case "Salaire":
        navigate("/ListeTransactions");
        break;

      case "Clients":
        navigate("/FClients");
        break;
      case "Payement":
        navigate("/ListeTransactions");
        break;
      

      default:
        console.log("tsy agnatiny...");
      // Code exécuté si aucune valeur ne correspond
    }
  }

    //navigate('/Timers');
    setInputValue(option);
    setIsOpen(false);
  };

  // Gestion des touches clavier
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) => (prev + 1) % filteredOptions.length);
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex(
        (prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length
      );
    } else if (e.key === "Enter" && filteredOptions.length > 0) {
      console.log(filteredOptions[highlightedIndex]);
      handleSelectOption(filteredOptions[highlightedIndex].value);
    }

    if (e.key === "Enter" && inputValue.trim() !== "") {
      alert(inputValue);
      setInputValue(""); // Réinitialise l'entrée
    }
  };

  /**************** DECRYPT FUNCTION ***************** */
  const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  return (
    <div
      ref={dropdownRef}
      className="mainAdvanced"
      style={{ position: "relative", width: "300px" }}
    >
      {/* Champ de saisie avec recherche */}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsOpen(true)}
        placeholder="Rechercher..."
      />

      {/* Menu déroulant conditionnel */}
      {isOpen && filteredOptions.length > 0 && (
        <ul>
          {filteredOptions.map((option, index) => (
            <li
              key={option.value}
              onClick={() => handleSelectOption(option.value)}
              onMouseEnter={() => setHighlightedIndex(index)}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor:
                  highlightedIndex === index ? "#ddd" : "transparent",
              }}
            >
              {option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdvancedSelect;
