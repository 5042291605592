import React, { useState,useEffect } from "react";
import { Link,replace,useNavigate,useParams } from "react-router-dom";
import {deleteTimer, fetchTimers,getHistoTrans} from "../api";
import CryptoJS from "crypto-js";
import { FaSearch, FaArrowLeft, FaArrowRight, FaTimes} from "react-icons/fa";
import { FiRefreshCcw } from 'react-icons/fi';
import "./Sidebar.css";

const SidebarP=({isOpen,onToggle})=> {
 
  const [searchTerm, setSearchTerm] = useState("");
  const [historique,setHistorique]=useState([]);
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const [filter,setFilter]=useState([]);
  const [init,setInit]=useState(true);
  const [idHisto,setIdHisto]=useState(0);
  const [isConnect,setIsConnect]=useState(false);
    const secretKey=CryptoJS.SHA1('josuo').toString();
    const [username,setUsername]=useState('');
  const navigate=useNavigate();

  const normalizeString = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  
  /**************** DECRYPT FUNCTION ***************** */
  const decrypt=(data)=>{
    const bytes=CryptoJS.AES.decrypt(data,secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  

  useEffect(() => {
   
   
    setLoading(true);
    //console.log(historique);



      //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
  /* console.log(decryptedData);*/
   if(retrievedData==null){
     setIsConnect(false);
      navigate('/JTime', { replace: true });
   }else{
     const decryptedData=decrypt(retrievedData);
     setUsername(decryptedData['username']);
     fetchTransactions(decryptedData['username']);
     setIsConnect(true);
 
   }

    
}, []);


const formatDate = (isoDateString) => {


  /*// Date donnée
const dateString = "2025-01-19T01:26:25.000Z";
const date = new Date(dateString);

// Récupérer le mois en lettres
const moisEnLettres = date.toLocaleString("fr-FR", { month: "long" });

console.log(moisEnLettres); // Affiche "janvier"

*/
  const date = new Date(isoDateString); // Crear un objeto Date
  //const day = String(date.getDate()).padStart(2, '0'); // Día con 2 dígitos
  //const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos
  const month = date.toLocaleString("fr-FR", { month: "long" });
  const year = date.getFullYear(); // Año completo
  return `${month}  ${year}`; // Formato DD/MM/YYYY
};

  // Gérer la saisie dans le champ de recherche
  const handleSearch = (event) => {
   
    // Diviser la recherche en mots-clés (par espace ou virgule)
    const value =  normalizeString(event.target.value); 
    setSearchTerm(event.target.value);
    
    // Supprime les espaces vides

    // Filtrer les éléments
    const filtered = historique.filter((item) =>
      normalizeString(formatDate(item.date_heure)).includes(value)||normalizeString(String(item.somme)).includes(value)) // Tous les mots doivent correspondre

    ;

    setFilter(filtered);
    setInit(false);
  };


const handleDelete = async(index) => {


   const response=await deleteTimer(index);

   if(response.data['success']){
    if(init){
    setHistorique(historique.filter((item) => item.ID !==index)); // Mettre à jour la liste
    }else{
          // Filtrer les éléments
    const filtered = historique.filter((item) =>
      (normalizeString(formatDate(item.date_heure)).includes(searchTerm)||normalizeString(String(item.somme)).includes(searchTerm))&&item.ID !==index) // Tous les mots doivent correspondre;
    setFilter(filtered)
    }
   }
 /* try{
      const response=await axios.delete('http://localhost:5000//api/delete_prepa/'+index);
      if(response.data['success']){
            const newData = [...data];
            newData.splice(index2, 1);
            setData(newData);
      }
   }catch(error){
    console.log(error)
   }
*/


};


  
  const fetchTransactions = async (username) => {
    try {
      const data = await getHistoTrans(username);
      console.log(data)
      setHistorique(data)
    } catch (error) {
      console.error('Erreur lors de la récupération des Transactions', error);

    }
  };


const handleLink = async(path,id) => {
  if(idHisto!=id){
  navigate(path);
  }else{

    navigate(path);
    navigate(0)
  }

  setIdHisto(id);
  
};

const onActualise=async()=>{
  const data = await getHistoTrans(username);
  console.log(data)
  setHistorique(data);
  if(!init){

         // Filtrer les éléments
    const filtered = historique.filter((item) =>
      normalizeString(formatDate(item.date_heure)).includes(searchTerm)||normalizeString(String(item.somme)).includes(searchTerm)) // Tous les mots doivent correspondre
    setFilter(filtered)
 

}
}




  return (

    (!isConnect)?"":(
    <aside className={`sidebar ${isOpen ? "open" : "closed"}`}>

      <nav>
    
      <div className="sidebar-header">
      
        {isOpen && (
          <div className="search-box">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Historique..."
              value={searchTerm}
             
              className="search-input"
  
              onChange={(e)=>handleSearch(e)}
            />
          </div>
        )}
 <button className="toggle-btn" onClick={onActualise} >
                 <FiRefreshCcw/>
           </button>
<button className="toggle-btn" onClick={onToggle}>

          {isOpen ? <FaArrowLeft /> : <FaArrowRight />}
        </button>
      </div>
      <div className="sidebarCorps">
              {!loading ? (
                <p>Chargement...</p>
              ):(historique.length > 0 || filter.length>0) ?( 
                <ul className="sidebar-menu">
                {init?(historique.map(item => (
                  <li key={item.id} ><div className="sous-menu"><a ><div>..{formatDate(item.date_heure)},  Montant à transferer:{item.somme}AR</div>   </a></div></li>
                ))):(filter.map(item => (
                  <li key={item.id} ><div className="sous-menu"><a><div>..{formatDate(item.date_heure)},  Montant à transferer:{item.somme}AR</div>   </a></div></li>
                )))}
        
             
        
                </ul>): (
                <p>Aucun élément trouvé.</p>
              )
        }
        </div>
      </nav>
    </aside>
    )
  );
}

export default SidebarP;
