import React,{useEffect,useState} from 'react'
import './Login.css'
import LoginForm from './LoginForm'
import CryptoJS from "crypto-js";
import { Link,useNavigate,useParams } from "react-router-dom";


function Login() {
    
  const [isConnect,setIsConnect]=useState(false);
  const secretKey=CryptoJS.SHA1('josuo').toString();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const {idLogin}=useParams();
    /**************** DECRYPT FUNCTION ***************** */
    const decrypt=(data)=>{
      const bytes=CryptoJS.AES.decrypt(data,secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    
  
    useEffect(() => {
     
    
      setLoading(true);

  
  
        //RECUPERATION DES DONNEES
    const retrievedData=localStorage.getItem('mombamomba');
    /* console.log(decryptedData);*/
     if(retrievedData==null){
       setIsConnect(false);
       console.log(retrievedData);
      
     }else{
       const decryptedData=decrypt(retrievedData);
       setIsConnect(true);
       navigate('/', { replace: true });
   
   
     }
  
      
  }, []);
  return (
    <div className='login-container'>
        <h1>Login</h1>
        {idLogin?
        <LoginForm/>: <LoginForm />
        }
 </div>
  )
}

export default Login