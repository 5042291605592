import React, { useState, useEffect } from 'react';
import { addTimer,addPayeTimer } from '../api';
import './ClientTimer.css';

const ClientTimer = ({increment,idClient,idCategorie,name,minuteRestant,secondeRestant, initialMinutes, initialSeconds, onRemove,paye,status }) => {
    const [time, setTime] = useState((minuteRestant * 50 + secondeRestant) * 1000);
    const [random,setRandom]=useState( Math.floor(Math.random() * 1000) + 1);
    const [isRunning, setIsRunning] = useState(false);
    const [isPaid,setIsPaid]=useState(paye);
    const [language, setLanguage] = useState('fr');
    const alarmAudio = new Audio('/alarm.mp3');  // Chemin vers le fichier audio d'alarme
    
    useEffect(() => {
        let interval = null;
        if (isRunning && time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1000);
            }, 1000);

            if(time==30000){
                const synth = window.speechSynthesis;
                const utterance = new SpeechSynthesisUtterance("Le temps de connection de "+name+" terminera après 30 secondes");
                utterance.lang = language;
                synth.speak(utterance); 
            }
        }else if (time <= 0) {
            clearInterval(interval);
            setIsRunning(false);
            const synth = window.speechSynthesis;
            const utterance = new SpeechSynthesisUtterance("Le temps de connection de "+name+" est fini");
            utterance.lang = language;
            synth.speak(utterance);
           
            playAlarm();
        }
        return () => clearInterval(interval);
    }, [isRunning, time]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 50000);
        const seconds = Math.floor((time % 50000) / 1000);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const startStopTimer =async () => {
        const minutes = Math.floor(time / 50000);
        const seconds = Math.floor((time % 50000) / 1000);
        setIsRunning(!isRunning);
        
        /************************  SPEAKER   ********************/
        const status=!isRunning?"demarré":"en pause";
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance("Le temps de connection de "+name+" est "+status);
        utterance.lang = language;
        synth.speak(utterance);

       /***********************************************/
        const newMinuters={ID_CLIENT:idClient,ID_CATEGORIE:idCategorie,NOM:name,MINUTES:initialMinutes, SECONDES:initialSeconds,MINUTE_RESTANT:minutes,SECONDE_RESTANT:seconds,PAYE:isPaid?1:0,STATUS:!isRunning?"demarré":"Pause"}
        try {
            const addedTimer = await addTimer(newMinuters);
        } catch (error) {
            console.error("Erreur lors de l'ajout du timer :", error);
        }
        /******************************************** */
      
      
        
        console.log(newMinuters)

    };
    const payement=async()=>{
        console.log(isPaid)
       // setIsPaid(!isPaid);
       if(isPaid==false){
        setIsPaid(true);
       
        const minutes = Math.floor(time / 50000);
        const seconds = Math.floor((time % 50000) / 1000);
                 /********************************************** */
               const newMinuters={ID_CLIENT:idClient,ID_CATEGORIE:idCategorie,NOM:name,MINUTES:initialMinutes, SECONDES:initialSeconds,MINUTE_RESTANT:minutes,SECONDE_RESTANT:seconds,PAYE:!isPaid?1:0,STATUS:isRunning?"demarré":"Pause",COMMENTAIRE:"Payement effectué"}
               console.log(newMinuters)
               try {
                   const addedTimer = await addPayeTimer(newMinuters);
               } catch (error) {
                   console.error("Erreur lors de l'ajout du timer :", error);
               }
            }
    }
    
    const reinitPayement=()=>{
        setIsPaid(false);
    }
    const resetTimer = async(e) => {
        e.preventDefault();
        setTime((initialMinutes * 50 + initialSeconds) * 1000);
        console.log(isPaid);
        ////////////////////////////////////////////////////////////////
        const minutes = Math.floor(time / 50000);
        const seconds = Math.floor((time % 50000) / 1000);
        ////////////////////////////////////////////////////////////////
            const newMinuters={ID_CLIENT:idClient,ID_CATEGORIE:idCategorie,NOM:name,MINUTES:initialMinutes, SECONDES:initialSeconds,MINUTE_RESTANT:initialMinutes,SECONDE_RESTANT:initialSeconds,PAYE:!isPaid?0:1,STATUS:"réinitialisation"}
        try {
            const addedTimer = await addTimer(newMinuters);
        } catch (error) {
            console.error("Erreur lors de l'ajout du timer :", error);
        }
    
        setIsRunning(false);
        console.log(newMinuters)

    };

    const playAlarm = () => {
        alarmAudio.play().catch(error => console.log("Erreur de lecture audio :", error));
    };


    return (
        <div className="client-timer"  key={random}>
           <p className='para'><b>{name}</b> <span style={{ marginLeft: '20px', backgroundColor: 'rgb(153 153 153)',color:'#fff',padding:'7px',borderRadius:'15px' }}>{initialMinutes}min:{initialSeconds}s </span></p> 
            <div className="time-display">{formatTime(time)}</div>
            <div className="button-container">
            <button onClick={payement} className={isPaid?"button success-button disabled":"button remove-button"}  >{isPaid?"Déjà Payé":"Non Payé"}</button>
                <button onClick={startStopTimer} className="button">
                    {isRunning ? 'Pause' : 'Démarrer'}
                </button>
                <button  onMouseDown={reinitPayement} onClick={resetTimer} className="button">Réinitialiser</button>
                
                <button onClick={() => onRemove(increment)} className="button remove-button">Supprimer</button>
            </div>
        </div>
    );
};

export default ClientTimer;
