import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom';
import { api_root,login } from '../../api';

import CryptoJS from 'crypto-js'


const LoginForm = ({motCle}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const initTypes=[{id:1,name:"J-Fotoana"},{id:2,name:"J-Mandoha"}];

  const [types,setTypes]=useState(initTypes);

  const secretKey=CryptoJS.SHA1('josuo').toString();
  
/**************** ENCRYPT AND DECRYPT FUNCTION ***************** */
  const encrypt=(data)=>{
    return CryptoJS.AES.encrypt(JSON.stringify(data),secretKey).toString();
  }

    useEffect(() => {
      console.log(motCle)
      if(motCle){
        selectedType(motCle);
      }

    },[]);

  /********* FIN CRYPT AND DECRYPT FUNCTION *********/
  const handleSubmit = async(event) => {
     event.preventDefault();
     // Hachage SHA-1 du mot de passe
     console.log('alert');
  

    //Hachage SHA-1 du mot de passe
  
    // Implement logic to handle login (e.g., fetch API)
    // For example, a basic validation:
    if (username === '' || password === '') {
      setErrorMessage('Please enter username and password');
      return;
    }else{
     
      
     // const hashedPassword = CryptoJS.SHA1(password).toString();
      const hashedPassword=password;
      
     // Envoi des données de connexion au backend
     try{
      const reponse= await login({username:username,password:hashedPassword});
    
      if (reponse.data.success) {
     
        //console.log('Login successful');
        const sessionData={"islogin":true,"user_id":reponse.data.user_id,"username":reponse.data.username,"email":reponse.data.email,"nom":reponse.data.nom,"image":reponse.data.image,type:selectedType};
     
        const encryptedData=encrypt(sessionData)
        localStorage.setItem('mombamomba',encryptedData)
        window.open(api_root,'_self');
        setUsername('');
        setPassword('');
        setErrorMessage('');
     
        // Redirection ou actions supplémentaires en cas de succès
      } else {
        setErrorMessage('Mot de passe ou username incorrect');
        //console.error('Login failed');
        // Actions supplémentaires en cas d'échec
      }

     }catch(error){
     /* console.log(error)*/
      setErrorMessage('Mot de passe ou username incorrect');
     }
   
  
 
  }

    
  };

  return (
    <form onSubmit={handleSubmit} className='form-login'>
      <div className="form-group">
        <label htmlFor="username">Username ou Email</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
         </div>
         <div className="form-group">
          <label htmlFor="password">Choix</label>
          <select 
                id="type" 
                value={selectedType} 
                onChange={(e) => setSelectedType(e.target.value)}
            >
               
                {types.map(type => (
                    <option key={type.id} value={type.name}>
                        {type.name}
                    </option>
                ))}
            </select>
            </div>
     
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button type="submit" className='btn-success'>Login</button>
      
      <Link to="https://gasymeva.pentadev-mada.com/signup" className='lien_compte'>creer une compte</Link>
      
    </form>
  );
};

export default LoginForm;