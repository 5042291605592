import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { getCategorie, addCategorie, updateCategorie, deleteCategorie } from "../../api";
import Modal from "../../Modal";

const Categorie = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [Categories, setCategories] = useState([]);
  const [newCategorie, setNewCategorie] = useState({
    libelle: "",
    qte: "",
    valeur: ""
  });
  const [editCategorie, setEditCategorie] = useState(null); // Stocke le Categorie à modifier
  const [feedback, setFeedback] = useState("");
  const [isModalOpenAjout, setIsModalOpenAjout] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [isConnect, setIsConnect] = useState(false);
  const [texte, setTexte] = useState("");
  const [init, setInit] = useState(true);
  const [filter, setFilter] = useState([]);
  const [type, setType] = useState("");
  const secretKey = CryptoJS.SHA1("josuo").toString();
  const navigate = useNavigate();
  const normalizeString = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  // Récupération initiale des Categories
  useEffect(() => {
    //RECUPERATION DES DONNEES
    const retrievedData = localStorage.getItem("mombamomba");
    /* console.log(decryptedData);*/
    if (retrievedData == null) {
      setIsConnect(false);
      console.log(retrievedData);
      navigate("/JTime");
    } else {
      const decryptedData = decrypt(retrievedData);
      if(decryptedData.type=="J-Mandoha"){
        navigate('/ListeTransactions');
      }
      console.log(decryptedData.username);
      setIsConnect(true);
      setUsername(decryptedData.username);
      setType(decryptedData.type);
      fetchCategories(decryptedData.username, decryptedData.type);
    }
  }, []);

  /**************** DECRYPT FUNCTION ***************** */
  const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const fetchCategories = async (username, type) => {
    try {
      const data = await getCategorie(username, type);
      if (init) {
        setCategories(data);
      } else {
        // Diviser la recherche en mots-clés (par espace ou virgule)
        const valeur = normalizeString(texte);
        // Filtrer les éléments
        const filtered = data.filter(
          (item) =>
            normalizeString(item.LIBELLE).includes(valeur) ||
            normalizeString(String(item.LIBELLE)).includes(valeur) ||
            normalizeString(String(item.QUANTITE)).includes(valeur)
        ); // Tous les mots doivent correspondre

        setFilter(filtered);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des Categories", error);
      setFeedback("Impossible de charger les Categories.");
    }
  };

  const filtrage = (valeur) => {
    // Filtrer les éléments
    const filtered = Categories.filter(
      (item) =>
        normalizeString(item.LIBELLE).includes(valeur) ||
        normalizeString(String(item.VALEUR)).includes(valeur) ||
        normalizeString(String(item.QUANTITE)).includes(valeur)
    ); // Tous les mots doivent correspondre
  };

  // Gérer la saisie dans le champ de recherche
  const handleSearch = (event) => {
    // Diviser la recherche en mots-clés (par espace ou virgule)
    const value = normalizeString(texte);

    // Supprime les espaces vides

    // Filtrer les éléments
    const filtered = Categories.filter(
      (item) =>
        normalizeString(item.LIBELLE).includes(value) ||
      normalizeString(String(item.VALEUR)).includes(value) ||
      normalizeString(String(item.QUANTITE)).includes(value)
    ); // Tous les mots doivent correspondre

    setFilter(filtered);
    setInit(false);
  };

  const ajoutNouveauCategorie = () => {
    setIsModalOpenAjout(true);
  };
  const handleEditCategorie = async (Categorie) => {
    console.log(Categorie);
    setEditCategorie(Categorie);
    setIsModalOpen(true);
  };
  // Ajouter un nouveau Categorie
  const handleAddCategorie = async () => {
    try {
      newCategorie.username = username;
      newCategorie.type = type;
      await addCategorie(newCategorie);
      fetchCategories(username, type);
      setIsModalOpenAjout(false);
      setFeedback("Categorie ajouté avec succès !");
      setNewCategorie({ libelle: "", qte: "", valeur: "" });
    } catch (error) {
      console.error("Erreur lors de l'ajout du Categorie", error);
      setFeedback("Erreur lors de l'ajout du Categorie.");
    }
  };

  // Mettre à jour un Categorie existant
  const handleUpdateCategorie = async () => {
    try {
      await updateCategorie(editCategorie.ID, editCategorie);
      fetchCategories(username, type);
      setFeedback("Categorie mis à jour avec succès !");
      setEditCategorie(null);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du Categorie", error);
      setFeedback("Erreur lors de la mise à jour du Categorie.");
    }
  };

  // Supprimer un Categorie
  const handleDeleteCategorie = async (id) => {
    try {
      await deleteCategorie(id);
      setFeedback("Categorie supprimé avec succès !");
      fetchCategories(username, type);
    } catch (error) {
      console.error("Erreur lors de la suppression du Categorie", error);
      setFeedback("Erreur lors de la suppression du Categorie.");
    }
  };

  const handleAjoutTimers = async (id) => {
    navigate("/ClientTimers/" + id);
  };

  return (
    <div className="container-timers">
      {feedback && <p style={{ color: "green" }}>{feedback}</p>}

      {/* Ajouter un nouveau Categorie */}
      <Modal show={isModalOpenAjout} onClose={() => setIsModalOpenAjout(false)}>
        <h3>Ajouter un Categorie</h3>
        <div className="body-modal">
          <input
            type="text"
            placeholder="Libellé"
            value={newCategorie.libelle}
            onChange={(e) =>
              setNewCategorie({ ...newCategorie, libelle: e.target.value })
            }
          />
          <input
            type="number"
            placeholder="Minute"
            value={newCategorie.qte}
            onChange={(e) =>
              setNewCategorie({ ...newCategorie, qte: e.target.value })
            }
          />
          <input
            type="number"
            placeholder="Valeur"
            value={newCategorie.valeur}
            onChange={(e) =>
              setNewCategorie({
                ...newCategorie,
                valeur: parseFloat(e.target.value),
              })
            }
          />
          <button onClick={handleAddCategorie}>Ajouter</button>
        </div>
      </Modal>

      {/* Modifier un Categorie */}
      {editCategorie && (
        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2>Modifier le Categorie</h2>
          <div className="body-modal">
            <input
              type="text"
              placeholder="libellé"
              value={editCategorie.LIBELLE}
              onChange={(e) =>
                setEditCategorie({ ...editCategorie, LIBELLE: e.target.value })
              }
            />
            <input
              type="number"
              placeholder="MINUTE"
              value={editCategorie.QUANTITE}
              onChange={(e) =>
                setEditCategorie({ ...editCategorie, QUANTITE: e.target.value })
              }
            />
            <input
              type="number"
              placeholder="Valeur"
              value={editCategorie.VALEUR}
              onChange={(e) =>
                setEditCategorie({
                  ...editCategorie,
                  VALEUR: parseFloat(e.target.value),
                })
              }
            />
            <button onClick={handleUpdateCategorie}>Enregistrer</button>
            <button className="btn-erreur" onClick={() => setEditCategorie(null)}>
              Annuler
            </button>
          </div>
        </Modal>
      )}
      <div className="table-container">
        {/* Liste des Categories */}
        <div className="filter-section">
          <button onClick={ajoutNouveauCategorie}>Ajout</button>
          {/* Sélecteur de mois */}

          {/* Sélecteur d'année */}

          <input
            type="text"
            value={texte}
            placeholder="recherche..."
            onChange={(e) => setTexte(e.target.value)}
          />

          {/* Bouton Filtrer */}
          <button onClick={(e) => handleSearch(e)}>Rechercher</button>
        </div>
        <table>
               <thead>
            <tr>
              <th>ID</th>
              <th>LIBELLE</th>
              <th>MINUTE</th>
              <th>VALEUR</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {init
              ? 

             (Categories.length!=0?
              Categories.map((Categorie) => (
                  <tr key={Categorie.ID}>
                    <td>{Categorie.ID}</td>
                    <td>{Categorie.LIBELLE}</td>
                    <td>{Categorie.QUANTITE}</td>
                    <td>{Categorie.VALEUR}</td>
                    <td>
                      <button
                        className="table-btn"
                        onClick={() => handleEditCategorie(Categorie)}
                      >
                        Modifier
                      </button>
                      <button
                        className="table-btn-suppr"
                        onClick={() => handleDeleteCategorie(Categorie.ID)}
                      >
                        Supprimer
                      </button>
            
                    </td>
                  </tr>
                )):<p style={{textAlign:"center"}}>Aucun élément...</p>)
              : (filter.length!=0? filter.map((Categorie) => (
                  <tr key={Categorie.ID}>
                    <td>{Categorie.ID}</td>
                    <td>{Categorie.LIBELLE}</td>
                    <td>{Categorie.QUANTITE}</td>
                    <td>{Categorie.VALEUR}</td>
                    <td>
                      <button
                        className="table-btn"
                        onClick={() => handleEditCategorie(Categorie)}
                      >
                        Modifier
                      </button>
                      <button
                        className="table-btn-suppr"
                        onClick={() => handleDeleteCategorie(Categorie.ID)}
                      >
                        Supprimer
                      </button>
                
                    </td>
                  </tr>
                )):<p style={{textAlign:"center"}}>Aucun Resultat...</p>)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Categorie;
