import React,{ useState, useRef, useEffect }  from "react";
import "./Header.css";
import {FaArrowRight} from "react-icons/fa";
import { FiRefreshCcw } from 'react-icons/fi';
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";


import DropdownProf from "./dropdown/DropdownProf";
import BlurDropdown from "./dropdown/BlurDropdown";
import AdvancedSelect from "./dropdown/AdvancedSelect";

function Header({isSidebarOpen,onToggle}) {

 const [openProfile,setOpenProfile]=useState(false); 
 const secretKey=CryptoJS.SHA1('josuo').toString();
 const [session,setSession]=useState({});
 const [type,setType]=useState("J-Fotoana");
 const navigate=useNavigate();
 const [isConnect,setIsConnect]=useState(false);
 const dropdownRef = useRef(null);

/**************** DECRYPT FUNCTION ***************** */
const decrypt=(data)=>{
  const bytes=CryptoJS.AES.decrypt(data,secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}


const handleClickOutside = (event) => {
  // Ferme le dropdown si le clic est en dehors de l'élément
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setOpenProfile(false)
  }
};


useEffect(()=>{
  //RECUPERATION DES DONNEES
  const retrievedData=localStorage.getItem('mombamomba');
 /* console.log(decryptedData);*/
  if(retrievedData==null){
    setIsConnect(false);
     navigate('/JTime', { replace: true });
  }else{
    const decryptedData=decrypt(retrievedData);
    setType(decryptedData.type);
    setIsConnect(true);
    /*console.log(decryptedData);*/
    setSession({image:'https://gasymeva.pentadev-mada.com/uploads/'+decryptedData['image']})


  }


  document.addEventListener('mousedown', handleClickOutside);
  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };


},[]);
 
 
  return (
    <header className="header" > 
      <div className="header-content">
      <div  className="header-left"  >
        {

        isConnect?(
          isSidebarOpen?"":
     
        <button className="toggle-btn-header" onClick={onToggle} >
                <FaArrowRight />
          </button>):""
       }
        <h1 className= { isConnect?(isSidebarOpen?"header-title":"header-tl-toggle-sleep"):"header-tl-toggle-sleep"}>{type}</h1>
        </div>
        <AdvancedSelect/>
         {

         (isConnect?<DropdownProf source={session['image']} />:<BlurDropdown/>)

         }


        </div>
      
    </header>
  );
}

export default Header;
