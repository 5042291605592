import axios from 'axios';

export const API_URL ='https://serveurs.pentadev-mada.com';
export const API_ROOT= 'https://fotoana.pentadev-mada.com';
//export const API_URL = 'http://localhost:5000';
//export const API_ROOT= 'http://localhost:3000';

export const api_root=()=>{return API_ROOT};
export const api_serveur=()=>{return API_ROOT};
export const fetchTimers = async () => {
    const response = await fetch(`${API_URL}/timers`);
    return response.json();
};


export const getTimer=(idHisto)=>{
     return axios.get(`${API_URL}/getTimers/`+idHisto);

}


export const getCapital=(username)=>{
  return axios.get(`${API_URL}/getCapital/`+username);

}
export const addTimer = async (timer) => {
    const response = await axios.post(`${API_URL}/timers`, timer);
    return response.data;
};

export const addPayeTimer = async (timer) => {
  const response = await axios.post(`${API_URL}/PayeTimers`, timer);
  return response.data;
};


export const deleteTimer = async (id) => {
   const response= await axios.delete(`${API_URL}/timers/${id}`);
    return response;
};

export const disconnectDevice = async (ip_address) => {
    await axios.post(`${API_URL}/disconnect`, { ip_address });
};


  // Ajouter un client
  export const login = async (data) => {
    const response = await axios.post(`${API_URL}/login`,data);
    return response;
  };


    // Ajouter un client
    export const logout = async (data) => {
      const response = await axios.post(`${API_URL}/logout`,data);
      return response;
    };
  




/*******************************************************************/
/********************** GESTION MONETAIRE **************************/
/*******************************************************************/
// Récupérer tous les clients
export const getClients = async (username,type) => {
    const response = await axios.get(`${API_URL}/clients/${username}/${type}`);
    return response.data;
  };


// Récupérer tous les clients
export const getCategorie = async () => {
  const response = await axios.get(`${API_URL}/categorie`);
  return response.data;
};



  // Récupérer tous les transactions
export const getTransactions = async (username) => {
  const response = await axios.get(`${API_URL}/transactions/${username}`);
  return response.data;
};


export const getSommeTrans = async (username) => {
  const response = await axios.get(`${API_URL}/SommeTrans/${username}`);
  return response.data;
};


export const getSommePayement = async (username) => {
  const response = await axios.get(`${API_URL}/SommePayement/${username}`);
  return response.data;
};




  // Récupérer tous les transactions
  export const getHistoTrans = async (username) => {
    const response = await axios.get(`${API_URL}/HistoTransactions/${username}`);
    return response.data;
  };
  
  




  // Récupérer tous les Rendements
  export const getRendement = async (username) => {
    const response = await axios.get(`${API_URL}/rendement/${username}`);
    return response.data;
  };
  

  // addCategorie, updateCategorie, deleteCategorie
  // Ajouter un client
  export const addClient = async (client) => {
    const response = await axios.post(`${API_URL}/clients`, client);
    return response.data;
  };


  export const addCategorie = async (categorie) => {
    const response = await axios.post(`${API_URL}/categorie`, categorie);
    return response.data;
  };




    // Ajouter un client
    export const addTransaction = async (transaction) => {
      const response = await axios.post(`${API_URL}/transactions`, transaction);
      return response.data;
    };
  
  // Mettre à jour un client
  export const updateClient = async (id, client) => {
    const response = await axios.put(`${API_URL}/clients/${id}`, client);
    return response.data;
  };

    // Mettre à jour un client
    export const updateCategorie = async (id, categorie) => {
      const response = await axios.put(`${API_URL}/categorie/${id}`, categorie);
      return response.data;
    };


  export const updateCapital = async (id, capital) => {
    const response = await axios.put(`${API_URL}/capital/${id}`, capital);
    return response.data;
  };
  
  // Supprimer un client
  export const deleteClient = async (id) => {
    const response = await axios.delete(`${API_URL}/clients/${id}`);
    return response.data;
  };
  

   // Supprimer un client
   export const deleteCategorie = async (id) => {
    const response = await axios.delete(`${API_URL}/categorie/${id}`);
    return response.data;
  };

    // Supprimer un client
    export const deleteTransaction = async (id) => {
      const response = await axios.delete(`${API_URL}/transactions/${id}`);
      return response.data;
    };
    
  
  
  // Récupérer les statistiques
  export const getStatistics = async () => {
    const response = await axios.get(`${API_URL}/clients/statistics`);
    return response.data;
  };
  
  
  // Récupérer l'historique des transactions d'un client
  export const getTransactionHistory = async (clientId) => {
      const response = await axios.get(`${API_URL}/transactions/client/${clientId}`);
      return response.data;
    };
    
    // Ajouter de l'argent (transaction de type dépôt)
    export const addMoney = async (clientId, amount) => {
      const response = await axios.post(`${API_URL}/clients/${clientId}/add-money`, { amount });
      return response.data;
    };
    
    // Retirer de l'argent (transaction de type retrait)
    export const subtractMoney = async (clientId, amount) => {
      const response = await axios.post(`${API_URL}/clients/${clientId}/subtract-money`, { amount });
      return response.data;
    };
    