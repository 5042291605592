import React, { useState, useRef, useEffect } from 'react';
import { Link} from "react-router-dom";
import { FiRefreshCcw } from 'react-icons/fi';
import "./DropdownProfil.css";
import CryptoJS from 'crypto-js';

import {  api_root,logout } from '../../api';

const DropdownProf = ({source}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [isConnect,setIsConnect]=useState(false);
    const [varSession,setVarSession]=useState({});
    const [type,setType]=useState('J-fotoana');
    const secretKey=CryptoJS.SHA1('josuo').toString();

    const changeType=async(motCle)=>{
       
        //console.log('Login successful');
        localStorage.removeItem('mombamomba');//SUPPRESSION DE VARIABLE SESSION COTE CLIENT
        const sessionData={"islogin":varSession['islogin'] ,"user_id":varSession["user_id"],"username":varSession['username'],"email":varSession["email"],"nom":varSession["nom"],"image":varSession["image"],type:motCle};
       
       console.log(sessionData)
        const encryptedData=encrypt(sessionData)
        localStorage.setItem('mombamomba',encryptedData);//CREATION DE NOUVELLE SESSION
         window.open(api_root,'_self');
    }
    const  deconnection=async(event)=>{
        //RECUPERATION DES DONNEES
        const retrievedData=localStorage.getItem('mombamomba');
        const decryptedData=decrypt(retrievedData);

           try{
               const reponse= await logout({user_id:decryptedData.user_id,user:decryptedData.username});
               console.log(reponse.data);
               if (reponse.data.success) {
                   localStorage.removeItem('mombamomba');
                   window.open(api_root,'_self');
                 // Redirection ou actions supplémentaires en cas de succès
               } 
              }catch(error){
               console.log(error)
              }
            
        }





    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        // Ferme le dropdown si le clic est en dehors de l'élément
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

                /**************** DECRYPT FUNCTION ***************** */
        const decrypt=(data)=>{
            const bytes=CryptoJS.AES.decrypt(data,secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
       

          /**************** ENCRYPT AND DECRYPT FUNCTION ***************** */
            const encrypt=(data)=>{
              return CryptoJS.AES.encrypt(JSON.stringify(data),secretKey).toString();
            }
          




    useEffect(() => {

        const retrievedData=localStorage.getItem('mombamomba');
        /* console.log(decryptedData);*/
         if(retrievedData==null){
           setIsConnect(false);
         
         }else{
           const decryptedData=decrypt(retrievedData);
           //console.log(decryptedData)
           setVarSession(decryptedData);
           setIsConnect(true);
           setType(decryptedData['type'])
       
       
         }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        
        


    }, []);

    return (
        <div ref={dropdownRef} className="dropdown">
      <img  src={source}
                    className="header-image dropdown-button"
                    onClick={toggleDropdown}
                    
                  />
            {isOpen && (
             <div className="flex flex-col dropdownProfile">
            <ul className="flex flex-col gap-4 menu"></ul>

              { (type=="J-Fotoana")&&
                <ul className="dropdown-menu">
                <li><Link to="/usersProfile">Profile</Link></li>
                <li><Link to="/">+ Timers</Link></li>
                <li><Link to="/Rendement">Rendement</Link></li>
                <li><Link to="/Clients">Clients</Link></li>
                <li><Link to="/Categorie">Categorie</Link></li>
                <li><Link to="https://gasymeva.pentadev-mada.com/">Paramètre</Link></li>
                <li onClick={()=>changeType("J-Mandoha")}><a>J-Mandoha <FiRefreshCcw/></a></li>
                <li><Link to="https://gasymeva.pentadev-mada.com/" >GasyMeva</Link></li>
                
                <li onClick={deconnection}>se déconnecter</li>
                </ul>
               }
       
                {
               (type=="J-Mandoha")&&
                <ul className="dropdown-menu">
                <li><Link to="/usersProfile">Profile</Link></li>
                <li><Link to="/ListeTransactions">+ Salaire</Link></li>
                <li><Link to="/FClients">Clients</Link></li>
                <li><Link to="https://gasymeva.pentadev-mada.com/">Paramètre</Link></li>
                <li onClick={()=>changeType("J-Fotoana")}><a>J-Fotoana <FiRefreshCcw/></a></li>
                <li><Link to="https://gasymeva.pentadev-mada.com/">GasyMeva</Link></li>
                <li onClick={deconnection}>se déconnecter</li>
                </ul>
              }
                {/*<ul className="dropdown-menu">
                <li onClick={deconnection}>se déconnecter</li>
                </ul>*/}
             </div>
             
            )}
        </div>
    );
};

export default DropdownProf;